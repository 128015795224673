import React from "react";
import Layout from "../components/layout";
import PrivacyPolicyBox from "../components/PrivacyPolicyBox";
import Seo from "../components/seo";

const PrivacyPolicy = () => {
  return (
    <Layout>
      <Seo title="Privacy Policy" description="noindex" />
      <PrivacyPolicyBox />
    </Layout>
  );
};

export default PrivacyPolicy;
